









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, Reports } from '@/store';

@Component({})
export default class WaterMeter extends Vue {
    @Global.State('lang') lang;
    @Global.Mutation('setDeviceClicked') setDeviceClicked;
    @Global.Mutation('setPageTitle') setPageTitle;
    @Reports.Getter('commissionedWaterMeter') commissionedWaterMeter;

    mounted(){
        this.setPageTitle('Water Meter');
    }

    waterMeterClicked(device){
        this.$router.push('/');
        this.setDeviceClicked(device.id);
    }
}
